<template>
    <div class="animated fadeIn">
        <b-card>
            <h4><i class="fa fa-table" aria-hidden="true"></i> Investigation Table</h4> <hr>

            <div class="row">
                <div class="col-sm-3">
                    <h5>Select Rows</h5>
                    <multiselect v-model="yAxisSelection" :options="yAxisOptions" :multiple="false" :close-on-select="true" deselect-label='' :allowEmpty="false" selectLabel='' @input="selectionChanged('yAxis')" ></multiselect>
                </div>                  
                <div class="col-sm-1 text-center"><h2 class="mt-3 pt-3">VS</h2></div> 
                <div class="col-sm-3">
                    <h5 class="vs-text">Select Columns</h5>
                    <multiselect v-model="xAxisSelection" :options="xAxisOptions" :multiple="false" :close-on-select="true" deselect-label='' :allowEmpty="false" selectLabel='' @input="selectionChanged('xAxis')" ></multiselect>
                    <!-- <multiselect v-model="xAxisDim2Selection" :options="xAxisDim2Options" :multiple="false" :close-on-select="true" deselect-label='' :allowEmpty="false" selectLabel='' @input="selectionChanged('xAxisDim2')" ></multiselect> -->
                </div>   
                <div class="col-sm-1 text-center"><i class="fas fa-arrow-circle-right fa-2x mt-3 pt-3"></i></div>
                <div class="col-sm-3">
                    <h5>Select Value</h5>
                    <multiselect :disabled="disableDataDropdown" v-model="dataSelection" :options="dataOptions" :multiple="false" :close-on-select="true" deselect-label='' :allowEmpty="false" selectLabel='' @input="selectionChanged('dataAxis')" ></multiselect>
                    <div v-if="dataSelection==='Total With Discount'" class="float float-right">Factor 5% GST <toggle-button @change="toggleGST($event)" v-model="calcGST" :value="true" :labels="{checked: 'GST', unchecked: ''}"/></div>
                </div>                                                   
            </div>

            <div class="row mt-3">
                <div class="col-sm-12">
                    <button @click="buildTable(true)" class="btn btn-primary"><i class="fas fa-analytics"></i> Build Investigation Table</button>        
                    <button v-if="displayPivotTable" @click="refreshData()" class="btn btn-info ml-3"><i class="fas fa-sync"></i> Refresh Data</button>     
                    <button @click="clearSelection()" class="btn btn-danger ml-3"><i class="fa fa-trash" aria-hidden="true" ></i> Clear</button>                                  
                    <hr>  
                </div>
            </div>

            <div v-if="loading" class="row animated fadeIn">
                <div class="col-sm-12 text-center">
                    <RotateSquare2 style="margin-left: 47%"></RotateSquare2>
                    Loading Investigation Table Data...
                </div>
            </div>

            <div v-if="displayPivotTable" class="row p-3 mt-3 mb-3">
                <div class="col-md-6">
                    <h5><i class="fa fa-filter" aria-hidden="true"></i> Row Filter </h5>
                    <multiselect :multiple="true" v-model="rowFilterSelection" :options="rowFilterOptions"  deselect-label='' :allowEmpty="false" selectLabel='' @remove="onRemoveRow" @select="onSelectRow" ></multiselect>                
                </div>
                <div class="col-md-6">
                    <h5><i class="fa fa-filter" aria-hidden="true"></i> Column Filter </h5>
                    <multiselect :multiple="true" v-model="columnFilterSelection" :options="columnFilterOptions"  deselect-label='' :allowEmpty="false" selectLabel='' @remove="onRemoveColumn" @select="onSelectColumn" ></multiselect> 
                </div>               
            </div>  


            <div v-if="displayPivotTable && isInvoicePayment" class="row p-3 mt-3 mb-3">
                <div class="col-md-6">
                    <h5><i class="far fa-ballot-check"></i> Project Selection </h5>
                    <multiselect :multiple="true" v-model="projectSelection" :options="projectOptions"  deselect-label='' :allowEmpty="true" selectLabel='' @remove="onRemoveProjectSelection" @input="onSelectProjectSelection" ></multiselect>                
                </div>

                <div class="col-md-6">
                    <h5><i class="far fa-ballot-check"></i> Afe Selection </h5>
                    <multiselect :multiple="true" v-model="afeSelection" :options="afeOptions"  deselect-label='' :allowEmpty="true" selectLabel='' @remove="onRemoveAfeSelection" @input="onSelectAfeSelection" ></multiselect> 
                </div>               
            </div>  


            <div class="" v-if="displayPivotTable" > 
                <hr>
                <InvestigationTable v-bind:rows="rowHeaders" v-bind:columns="columnHeaders" v-bind:data="pivotTableData"> </InvestigationTable>      
            </div>
        </b-card>
    </div>
</template>

<script>
import Vue from 'vue'
import {Multiselect} from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'
import InvestigationTable from './../../components/InvestigationTable'
import { ToggleButton } from 'vue-js-toggle-button'
import { PassThrough } from 'stream';
import moment from 'moment';

/* Vue.use(ClientTable) */

export default {
    name: 'WtsPivotTable',
    components: {
        Multiselect,
        InvestigationTable,
        RotateSquare2,
        ToggleButton
    },
    props : ['filterSelection', 'masterInvoiceProjectSelection','masterInvoiceAfeSelection','masterInvoiceVendorSelection'],

    data () {
        return {
            //
            //  STATUS
            //
            loading : false,
            displayPivotTable : false,
            
            //  Flag for special case for invoice payment (to display filters, custom behaviour, etc)
            isInvoicePayment : false,   
            removeAfe : false,

            //
            //  DROPDOWNS
            //

            //  X-AXIS Headers
            xAxisOptions : ['Well','AFE / PO', 'Category','Project','Cost Code #1 / Major','Cost Code #2 / Minor','Cost Code #3 / Description','Line Item'],        
            xAxisSelection : null,

            //  X-AXISDIM2 Headers
            xAxisDim2Options : ['Well','AFE / PO', 'Category','Project','Cost Code #1 / Major','Cost Code #2 / Minor','Cost Code #3 / Description','Line Item'],        
            xAxisDim2Selection : null,

            //  Y-AXIS Headers
            yAxisOptions : ['Vendor','Line Item','Project','Days','Months','Years'],
            yAxisSelection : null,

            //  DATA Headers
            dataOptions : ['Total With Discount','Quantity'],
            dataSelection : null ,

            //  For special filtering for invoice payment
            projectOptions : [],
            projectSelection : [],

            afeOptions : [],
            afeSelection : [],

            //  Toggle GST button
            calcGST : false,
            
            // Flag for disabling the data dropdown
            disableDataDropdown : true,

            //
            //  Filtering
            //

            rowFilterOptions:[],
            columnFilterOptions:[],
            
            rowFilterSelection:[],
            columnFilterSelection:[],

            columnRemovedItems:[],   //  Temp holds the removed items to add back later
            rowRemovedItems:[],    
            projectRemovedItems:[],
            afeRemovedItems:[],

            //
            //  LIST DATA MODELS
            //

            listOfProjects : [],
            listOfAfes : [],
            listOfServicesLines : [],
            listOfInvoices: [],
            listOfServiceEstimates : [],

            //
            //  PIVOT TABLE DATA MODELS
            //
            rowHeaders : [],
            columnHeaders : [],
            pivotTableData : [],

            //  This is just a manual list to keep track of what x-y and data selection "combinations" have been completed
            //  Used to display message the pivot table for specific selection isnt done yet
            //  array of arrays, 2nd array indicates the values
            //  [dataSelection, xAxis (columns), yAxis (rows)]
            pivotCompletion : [

                ['Total With Discount', 'Line Item' , 'Days'],
                ['Total With Discount', 'AFE / PO' , 'Days'],
                ['Total With Discount', 'Category' , 'Days'],
                ['Total With Discount', 'Project' , 'Days'],   
                ['Total With Discount', 'Cost Code #1 / Major' , 'Days'],    
                ['Total With Discount', 'Cost Code #2 / Minor' , 'Days'],    
                ['Total With Discount', 'Cost Code #3 / Description' , 'Days'],    
                ['Total With Discount', 'Well' , 'Days'],   

                ['Total With Discount', 'Line Item' , 'Months'],
                ['Total With Discount', 'AFE / PO' , 'Months'],
                ['Total With Discount', 'Category' , 'Months'],
                ['Total With Discount', 'Project' , 'Months'],   
                ['Total With Discount', 'Cost Code #1 / Major' , 'Months'],    
                ['Total With Discount', 'Cost Code #2 / Minor' , 'Months'],    
                ['Total With Discount', 'Cost Code #3 / Description' , 'Months'],    
                ['Total With Discount', 'Well' , 'Months'],   

                ['Total With Discount', 'Line Item' , 'Years'],
                ['Total With Discount', 'AFE / PO' , 'Years'],
                ['Total With Discount', 'Category' , 'Years'],
                ['Total With Discount', 'Project' , 'Years'],   
                ['Total With Discount', 'Cost Code #1 / Major' , 'Years'],    
                ['Total With Discount', 'Cost Code #2 / Minor' , 'Years'],    
                ['Total With Discount', 'Cost Code #3 / Description' , 'Years'],    
                ['Total With Discount', 'Well' , 'Years'],  

                ['Total With Discount', 'Well', 'Vendor'],
                ['Total With Discount', 'AFE / PO', 'Vendor'],  
                ['Total With Discount', 'Category', 'Vendor'],
                ['Total With Discount', 'Project','Vendor'], 
                ['Total With Discount', 'Cost Code #1 / Major','Vendor'], 
                ['Total With Discount', 'Cost Code #2 / Minor','Vendor'], 
                ['Total With Discount', 'Cost Code #3 / Description','Vendor'],    

                ['Total With Discount', 'Invoice Payment','Vendor'],    

                ['Total With Discount', 'Well', 'Line Item'],
                ['Total With Discount', 'AFE / PO', 'Line Item'],  
                ['Total With Discount', 'Category', 'Line Item'],
                ['Total With Discount', 'Project','Line Item'], 
                ['Total With Discount', 'Cost Code #1 / Major','Line Item'], 
                ['Total With Discount', 'Cost Code #2 / Minor','Line Item'], 
                ['Total With Discount', 'Cost Code #3 / Description','Line Item'],   

                ['Total With Discount', 'Well', 'Project'],
                ['Total With Discount', 'AFE / PO', 'Project'],  
                ['Total With Discount', 'Category', 'Project'],
               /*  ['Total With Discount', 'Project','Project'],  */
                ['Total With Discount', 'Cost Code #1 / Major','Project'], 
                ['Total With Discount', 'Cost Code #2 / Minor','Project'], 
                ['Total With Discount', 'Cost Code #3 / Description','Project'],                   
                
                ['Quantity','Project','Line Item'],
                ['Quantity','Well','Line Item'],
                ['Quantity','AFE / PO','Line Item'],
                ['Quantity','Category','Line Item'],
                ['Quantity','Cost Code #1 / Major','Line Item'],
                ['Quantity','Cost Code #2 / Minor','Line Item'],
                ['Quantity','Cost Code #3 / Description','Line Item'],
            ]
        }
    },
    methods: {  

        //
        //  Primary method for building pivot table 
        //
        async buildTable(resetFilters){
                

                /* 
                [DONE] Vendor vs AFE ==>Total cost
                [DONE] Vendor vs well ==>Total cost
                [DONE] Vendor vs Category ==>Total cost
                [DONE] Vendor vs Project ==>Total cost
                [DONE] Vendor vs CC1 ==>Total cost
                [DONE] Vendor vs CC2 ==>Total cost
                [DONE] Vendor vs CC3 ==>Total cost

                [DONE] Line_item vs AFE ==>Total cost
                Line_item vs well ==>Total cost
                Line_item vs Category ==>Total cost
                Line_item vs Project ==>Total cost
                Line_item vs CC1 ==>Total cost
                Line_item vs CC2 ==>Total cost
                Line_item vs CC3 ==>Total cost 
                */


            if(this.xAxisSelection === null){
                this.$toasted.show('Please select xAxis', {type: 'error', duration: '3000'});
                return;
            }

            if(this.yAxisSelection === null){
                this.$toasted.show('Please select yAxis', {type: 'error', duration: '3000'});
                return;
            }

            if(this.dataSelection === null){
                this.$toasted.show('Please make a Data selection', {type: 'error', duration: '3000'});
                return;
            }

            let pivotTableRouteExists = false;
            this.pivotCompletion.forEach(route => {
                /* console.log('data selection');
                console.log(this.dataSelection);
                console.log('x-axis selection');
                console.log(this.xAxisSelection);
                console.log('y-axis selection');
                console.log(this.yAxisSelection); */

                console.log(route[0]);
                console.log(route[1]);
                console.log(route[2]);
                console.log("\n\n\n");
                if(route[0] === this.dataSelection && route[1] === this.xAxisSelection && route[2] === this.yAxisSelection){
                    pivotTableRouteExists = true;
                }
            });

            if(!pivotTableRouteExists){
                this.$toasted.show('Pivot table based on specified criteria is unavailable', {type: 'error', duration: '3000'});
                this.loading = false;
                this.displayPivotTable = false;
                return;
            }



            //===============================================================//
            //                          GET DATA FROM API
            //===============================================================//
            
            //  THIS LINE IS FOR TESTING
            if(typeof this.$session.get('listOfProjects') === 'undefined'){
                const refreshData = await this.refreshData();
            } else {
                console.log('getting the session');
                this.listOfProjects = this.$session.get('listOfProjects');
                this.listOfAfes = this.$session.get('listOfAfes');                
                this.listOfInvoices = this.$session.get('listOfInvoices');
                this.listOfServicesLines = this.$session.get('listOfServicesLines');      
                this.listOfServiceEstimates = this.$session.get('listOfServiceEstimates');                        
            }



            //
            //  If this is being referenced from a modal, filter based on the incoming props
            //  
            if(typeof this.masterInvoiceAfeSelection != 'undefined'){
                console.log("GOT A PROP masterInvoiceAfeSelection");
                console.log(this.masterInvoiceAfeSelection);
                let filterservicelines = [];
                this.masterInvoiceAfeSelection.forEach(afe => {
                    filterservicelines = filterservicelines.concat(this.listOfServicesLines.filter(line => line.afenum === afe.afenum));
                })
                this.listOfServicesLines = filterservicelines;

                this.listOfAfes = this.masterInvoiceAfeSelection;
            }   

            if(typeof this.masterInvoiceProjectSelection != 'undefined') {
                console.log("GOT A PROP masterInvoiceProjectSelection");
                console.log(this.masterInvoiceProjectSelection);
                let filterservicelines = [];
                this.masterInvoiceProjectSelection.forEach(project => {
                    filterservicelines = filterservicelines.concat(this.listOfServicesLines.filter(line => line.projectid === project.projectid));
                })
                this.listOfServicesLines = filterservicelines;
            }

            if(typeof this.masterInvoiceVendorSelection != 'undefined'){
                console.log("GOT A PROP masterInvoiceVendorSelection");
                console.log(this.masterInvoiceVendorSelection);
                this.listOfServicesLines = this.listOfServicesLines.filter(line => line.vid === this.masterInvoiceVendorSelection.vid);
            }

            //
            //  Dump all lists to console for info
            //
            console.log("List of projects");
            console.dir(this.listOfProjects);
            console.log("List of AFEs");
            console.dir(this.listOfAfes);
            console.log("List of invoices");
            console.dir(this.listOfInvoices);
            console.log("List of service lines");
            console.dir(this.listOfServicesLines);
            console.log("List of all service estimates");
            console.dir(this.listOfServiceEstimates)

            //===============================================================//
            //                          BUILD DATA MODEL
            //===============================================================//

            //
            //  Reset the model
            // 
            console.log("resetting model");
            this.rowHeaders = [];
            this.columnHeaders = [];
            this.pivotTableData = [[]];

            //
            //  Build data based on selected criteria
            //


            if(this.dataSelection === "Total With Discount"){
                let xFilterBy;
                let yFilterBy;

                //debugger;

                //  ===============================
                //  START determine yAxis header
                //  ===============================
                if(this.yAxisSelection === "Vendor"){
                    this.lodash.uniqBy(this.listOfInvoices,'vcn').forEach(invoice => {
                        this.columnHeaders.push(invoice.vcn);
                    })
                    yFilterBy = 'vcn';                     
                }
                else if(this.yAxisSelection === "Line Item"){
                    this.listOfServicesLines.forEach(line => {
                        this.columnHeaders.push(line.name);
                    });
                    this.columnHeaders = this.lodash.uniq(this.columnHeaders);
                    yFilterBy = 'name';  
                } 
                else if(this.yAxisSelection ==="Project"){
                    this.listOfProjects.forEach(project => {
                        this.columnHeaders.push(project.projectname);
                    })
                    yFilterBy = 'projectname';                       
                }
                else if (this.yAxisSelection === "Days") {
                    this.lodash.uniqBy(this.listOfServicesLines,'end_date').forEach(line => {
                        this.columnHeaders.push(line.end_date);
                    });
                    yFilterBy = 'end_date'; 
                }
                else if (this.yAxisSelection === "Months") {

                    for(let line of this.listOfServicesLines){
                        let end_month_date = moment(line.end_date).endOf('month').format('MMMM-YYYY');
                        line.end_month_date = end_month_date;
                    }

                    this.lodash.uniqBy(this.listOfServicesLines,'end_month_date').forEach(line => {
                        this.columnHeaders.push(line.end_month_date);
                    })

                    yFilterBy = 'end_month_date'; 
                }  
                else if (this.yAxisSelection ==='Years'){
                    for(let line of this.listOfServicesLines){
                        let end_date_year = moment(line.end_date).endOf('year').format('YYYY');
                        line.end_date_year = end_date_year;
                    }

                    this.lodash.uniqBy(this.listOfServicesLines,'end_date_year').forEach(line => {
                        this.columnHeaders.push(line.end_date_year);
                    })

                    yFilterBy = 'end_date_year'; 
                }            
                else {
                    this.$toasted.show('yAxis Headers not set', {type: 'error', duration: '1000'});
                    return;
                }

                //  Populate the row options/selection for first time
                if(this.rowFilterOptions.length < 1 || resetFilters){
                    this.rowFilterOptions = this.columnHeaders;
                    this.rowFilterSelection = this.columnHeaders;
                    this.rowRemovedItems = [];
                }
                
                //  Filter Column Headers Based On Selection
                this.columnHeaders = this.lodash.difference(this.columnHeaders,this.rowRemovedItems);

                //  ===============================
                //  END determine yAxis header
                //  =============================== 
                
                //  ===============================
                //  START determine xAxis header
                //  ===============================                
                xFilterBy = this.determineXAxisHeader(resetFilters);
                //  ===============================
                //  END determine xAxis header
                //  =============================== 
                if(xFilterBy === 'invoice_payment'){
                    this.buildTableDataModelInvoicePayment();
                } else {
                    this.buildTableDataModelTotalWithDiscount(xFilterBy,yFilterBy);  
                }

                this.loading = false;
                this.displayPivotTable = true;    

            }  else if(this.dataSelection === "Quantity"){
                let xFilterBy;
                let yFilterBy;

                //  ===============================
                //  START determine yAxis header
                //  =============================== 
                if(this.yAxisSelection === "Line Item"){
                    this.listOfServicesLines.forEach(line => {
                        this.columnHeaders.push(line.name + ' ' + '(' + line.uom + ')');
                    });
                    this.columnHeaders = this.lodash.uniq(this.columnHeaders);
                    yFilterBy = 'name';        
                 } else {
                     this.$toasted.show('yAxis Headers not set', {type: 'error', duration: '1000'});
                     return;
                 }     
                 
                //  Filter Column Headers Based On Selection
                this.columnHeaders = this.lodash.difference(this.columnHeaders,this.rowRemovedItems);
                //  ===============================
                //  END determine yAxis header
                //  =============================== 

                //  ===============================
                //  START determine xAxis header
                //  =============================== 
                xFilterBy = this.determineXAxisHeader(resetFilters);           
                //  ===============================
                //  END determine xAxis header
                //  ===============================   
                
                //  Populate the row options/selection for first time
                if(this.rowFilterOptions.length < 1 || resetFilters){
                    this.rowFilterOptions = this.columnHeaders;
                    this.rowFilterSelection = this.columnHeaders;
                    this.rowRemovedItems = [];
                }
                

                this.buildTableDataModelQuantity(xFilterBy,yFilterBy);  
                this.loading = false;
                this.displayPivotTable = true;    
            }   
        },
        async refreshData(){

            this.listOfProjects = [];
            this.listOfAfes = [];
            this.listOfServicesLines = [];
            this.listOfInvoices = [];
            this.listOfServiceEstimates = [];

            this.rowHeaders = [];
            this.columnHeaders = [];
            this.pivotTableData = [];

            /* this.$session.set('listOfProjects',this.listOfProjects);
            this.$session.set('listOfAfes',this.listOfAfes);                
            this.$session.set('listOfInvoices',this.listOfInvoices);
            this.$session.set('listOfServicesLines',this.listOfServicesLines);    */

            //
            //  Start loading 
            //
            this.loading = true;
            this.displayPivotTable = false;

            //
            //  Get all projects
            //
            const projects = await this.getAllProjects();

            //
            //  Get all invoices (unprocessed and processed) 
            //
            for(const project of this.listOfProjects){
                const procssedinv = await this.getAllProcessedInvoices(project.projectid);
                const unprocessedinv = await this.getAllUnprocessedInvoices(project.projectid);
            }

            //
            //  Filter out all invoices that have inv_pass = 0 (pending wss)
            //
            this.listOfInvoices = this.listOfInvoices.filter( o => o.inv_passed != 0 );

            //
            //  Get all service line items
            //
            let listOfInvoiceIds = [];
            this.listOfInvoices.forEach(inv => {
                listOfInvoiceIds.push(inv.invoiceid) ;
            });

            let payload = {
                invoiceid : listOfInvoiceIds
            }
            
            await this.$axios.post('/wts/get/invoice_services/',payload)
            .then(response => {
                this.listOfServicesLines = response.data.result;
            }).catch(error => {})

            //
            //  To make things easier, but the VCN in every service line
            //
            this.listOfServicesLines.forEach(line =>{
                line.vcn = this.listOfInvoices.find(invoice => {
                    return invoice.vid === line.vid;
                }).vcn;
            });

            //
            //  To make things easier, but the VCN in every service line
            //
            this.listOfServicesLines.forEach(line =>{
                line.vcn = this.listOfInvoices.find(invoice => {
                    return invoice.vid === line.vid;
                }).vcn;
            });

            //
            //  Get all AFEs
            //
            this.listOfAfes = this.lodash.uniqBy(this.listOfServicesLines,'pwcaid');


            //
            //  Get all service estimates
            //

            //Get a list of a project IDs
            let listOfProjectIds = [];
            this.lodash.uniqBy(this.listOfInvoices, 'projectname').forEach(o => {
                    listOfProjectIds.push(o.projectid);
            });

            let listOfAfeNums = [];
            this.listOfAfes.forEach(afe => {
                listOfAfeNums.push(afe.pwcaid);
            })

            let payload2 = {
                pwcaid : listOfAfeNums,
                projectid : listOfProjectIds
            }
            await this.$axios.post('/service_estimate_v2/get/',payload2)
            .then(response => {
                this.listOfServiceEstimates = response.data.result;                   
            }).catch(error => {})


            console.log('setting the session');
            this.$session.set('listOfProjects',this.listOfProjects);
            this.$session.set('listOfAfes',this.listOfAfes);                
            this.$session.set('listOfInvoices',this.listOfInvoices);
            this.$session.set('listOfServicesLines',this.listOfServicesLines);   
            this.$session.set('listOfServiceEstimates',this.listOfServiceEstimates); 
            this.loading = false;
        
            this.buildTable(true);

        },
        //
        //  Generic interface for calculating the total with discount based on x/y filters
        //
        buildTableDataModelTotalWithDiscount(xFilter,yFilter){
            this.pivotTableData = [];
            for(let y = 0 ; y < this.columnHeaders.length ; y++){
                this.pivotTableData.push([]);
                this.pivotTableData[y].push(new Array(this.rowHeaders.length));
                for(let x = 0 ; x < this.rowHeaders.length ; x++){                       
                    let total = 0;   
                    if(this.xAxisSelection=== 'Line Item'){
                        this.listOfServicesLines.filter(line => {
                            return line[yFilter] === this.columnHeaders[y];
                        }).filter(line => {
                            let str = this.rowHeaders[x].split('(')[0];
                            str = str.substr(0, str.length-1);
                            return line[xFilter] === str;
                        }).filter(line => {
                            let str = this.rowHeaders[x].split('(')[1];
                            return line.uom === str.substr(0, str.length-1);
                        }).forEach(line => {
                            let lineTotal = this.calcLineTotalWithAfePercentage(line);
                            if(this.calcGST){
                                total += (lineTotal + (lineTotal * 0.05));
                            } else{
                                total += lineTotal ; 
                            }
                        })
                    } else {
                        this.listOfServicesLines.filter(line => {
                            return line[yFilter] === this.columnHeaders[y];
                        }).filter(line => {
                            return line[xFilter] ===  this.rowHeaders[x];
                        }).forEach(line => {
                            let lineTotal = this.calcLineTotalWithAfePercentage(line);
                            if(this.calcGST){
                                total += (lineTotal + (lineTotal * 0.05));
                            } else{
                                total += lineTotal ; 
                            }
                        })
                    }       
                    this.pivotTableData[y][x] = '$ ' + this.formatPrice(total);
                }
            }
        },
        //
        //  Generic interface for calculating the discount based on x/y filters
        //        
        buildTableDataModelDiscount(xFilter,yFilter){
            this.pivotTableData = [];
            for(let y = 0 ; y < this.columnHeaders.length ; y++){
                this.pivotTableData.push([]);
                this.pivotTableData[y].push(new Array(this.rowHeaders.length));
                for(let x = 0 ; x < this.rowHeaders.length ; x++){                 
                    let discountTotal = 0;
                    this.listOfServicesLines.filter(line => {
                        return line[yFilter] === this.columnHeaders[y];
                    }).filter(line => {
                        return line[xFilter] ===  this.rowHeaders[x];
                    }).forEach(line => {
                        //
                        //  Note : DIFFERENT CALCULATION HERE
                        //
                        discountTotal += (line.quantity * line.pu) * (line.discount/100);
                    })
                    this.pivotTableData[y][x] = '$ ' + this.formatPrice(discountTotal);
                }
            }
        },
        buildTableDataModelQuantity(xFilter,yFilter){
            this.pivotTableData = [];
            for(let y = 0 ; y < this.columnHeaders.length ; y++){
                this.pivotTableData.push([]);
                this.pivotTableData[y].push(new Array(this.rowHeaders.length));
                for(let x = 0 ; x < this.rowHeaders.length ; x++){                 
                    let quantity = 0;
                    this.listOfServicesLines.filter(line => {
                        //
                        //  Parse string ex : 'name something (uom)' -> 'name something' 
                        //
                        let str = this.columnHeaders[y].split('(')[0];
                        str = str.substr(0, str.length-1);
                        return line[yFilter] === str;
                    }).filter(line => {
                        //
                        //  Parse string ex : 'name (uom)' -> 'uom'
                        // 
                        let str = this.columnHeaders[y].split('(')[1];
                        return line.uom === str.substr(0, str.length-1);
                    }).filter(line => {
                        return line[xFilter] ===  this.rowHeaders[x];
                    }).forEach(line => {
                        quantity += line.quantity
                    })
                    this.pivotTableData[y][x] = quantity;
                }
            }
        },

        async buildTableDataModelInvoicePayment(){

            //  First we need to populate each service line with the accountant_paid status 
            this.listOfServicesLines.forEach(line => {
                line.accountant_paid = this.listOfInvoices.find( o => {
                    return o.invoiceid === line.invoiceid
                }).accountant_paid;
            })

            //  Next we need to filter out any lines that are before wss approval or rejected
            //  Remove 0, 2 9, 5, 14, 24, 34, 44, 54
            let templines = [];
            this.listOfServicesLines.forEach(line => {
                if(line.inv_passed != 0 &&
                    line.inv_passed != 2 &&
                    line.inv_passed != 9 &&
                    line.inv_passed != 5 &&
                    line.inv_passed != 14 &&
                    line.inv_passed != 24 &&
                    line.inv_passed != 34 &&
                    line.inv_passed != 44 &&
                    line.inv_passed != 54)
                    {
                        templines.push(line);
                    }
            })
            

            //Before calcing anything, lets get a list of all the projects and AFEs
            if(this.projectOptions.length === 0){
                this.lodash.uniqBy(this.listOfInvoices, 'projectname').forEach(o => {
                    this.projectOptions.push(o.projectname);
                });
                this.projectOptions = this.lodash.uniq(this.projectOptions);
            }

            // Reset afe options to only allow whats been selected in projects
            this.afeOptions = [];
            this.projectSelection.forEach(projectname => {
                templines.forEach(line => {
                    if(line.projectname === projectname){
                        this.afeOptions.push(line.afenum);
                    }
                })
            })
            this.afeOptions = this.lodash.uniq(this.afeOptions);

            //  Now filter the lines based on these any selections made...
            let tempAfeAndProjectLines = [];

            this.projectSelection.forEach(projectname => {
                this.afeSelection.forEach(afenum=>{
                    templines.forEach(line => {
                        if(line.projectname === projectname && line.afenum === afenum){
                            console.log(line.afenum);
                            tempAfeAndProjectLines.push(line);
                        }
                    })
                })        
            })

        
            tempAfeAndProjectLines.forEach( line => {
                this.listOfServiceEstimates.forEach(estimate=> {
                    if(line.serviceid === estimate.serviceid){
                        line.estimate_quantity = estimate.qty1;
                        line.missing_est = false;
                    }
                    if(line.estimate_quantity === 'undefined' || line.estimate_quantity === undefined){
                        line.estimate_quantity = 0;
                        line.missing_est = true;
                    }
                })
            })

            templines = tempAfeAndProjectLines;

            //  Next, we need to get the total, per vendor, for each line
            //  Do this by creating a temp object to store this list of vendors      
            //  Also need to reset the list of vendors from the row headers   
            let listOfVendors = [];
            this.columnHeaders = [];

            this.lodash.uniqBy(templines,'vendor_company_name').forEach(l => {
                listOfVendors.push({'vcn' : l.vcn});
                this.columnHeaders.push(l.vcn);
                
            })

            //  Also filter out any vendors that were removed from the rows
            this.rowRemovedItems.forEach(vendor => {
                let i = 0;
                listOfVendors.forEach(lvendors => {
                    if(lvendors.vcn === vendor){
                        listOfVendors.splice(i,1);
                        this.columnHeaders.splice(this.columnHeaders.indexOf(vendor),1);
                    }
                    i++;
                })
            })

            listOfVendors.forEach(vendor => {
                
                vendor.append_missing_est_text = false;

                templines.filter(line => {
                    return line.vendor_company_name === vendor.vcn;
                }).forEach(line => {

                    if(line.missing_est){
                        vendor.append_missing_est_text = true;
                    }

                    //  init to 0 if undefined
                    if(vendor['Total Invoice Amount'] === undefined){ (vendor['Total Invoice Amount'] = 0 ) }
                    if(vendor['Paid Invoice Amount'] === undefined){ (vendor['Paid Invoice Amount'] = 0 ) }
                    if(vendor['Unpaid Invoice Amount'] === undefined){ (vendor['Unpaid Invoice Amount'] = 0 ) }
                    if(vendor['Total Estimate Amount'] === undefined){ (vendor['Total Estimate Amount'] = 0 ) }

                    //  Calc it up
                    if(this.calcGST){
                        vendor['Total Invoice Amount'] += this.calcLineTotalWithAfePercentage(line) + (this.calcLineTotalWithAfePercentage(line) * 0.05);
                        vendor['Total Estimate Amount'] += ((line.estimate_quantity * line.pu) - ((line.invoice_discount/100) * line.estimate_quantity * line.pu))  + (((line.estimate_quantity * line.pu) - ((line.invoice_discount/100) * line.estimate_quantity * line.pu)) * 0.05);            
                        if(line.accountant_paid === 1){
                            vendor['Paid Invoice Amount'] += this.calcLineTotalWithAfePercentage(line) + (this.calcLineTotalWithAfePercentage(line) * 0.05);
                            vendor['Unpaid Invoice Amount'] += 0;
                        } else {
                                vendor['Paid Invoice Amount'] += 0;
                                vendor['Unpaid Invoice Amount'] += this.calcLineTotalWithAfePercentage(line)  + (this.calcLineTotalWithAfePercentage(line) * 0.05);
                        }
                    
                    } else{
                        vendor['Total Invoice Amount'] += this.calcLineTotalWithAfePercentage(line);
                        vendor['Total Estimate Amount'] += ((line.estimate_quantity * line.pu) - ((line.invoice_discount/100) * line.estimate_quantity * line.pu));
                        if(line.accountant_paid === 1){
                            vendor['Paid Invoice Amount'] += this.calcLineTotalWithAfePercentage(line);
                            vendor['Unpaid Invoice Amount'] += 0;
                        } else {
                                vendor['Paid Invoice Amount'] += 0;
                                vendor['Unpaid Invoice Amount'] += this.calcLineTotalWithAfePercentage(line);
                        }                    
                    }
                            
                })
            })

            //  Now iterate this list...
           this.pivotTableData = [];
            for(let y = 0 ; y < this.columnHeaders.length ; y++){
                this.pivotTableData.push([]);
                this.pivotTableData[y].push(new Array(this.rowHeaders.length));
                for(let x = 0 ; x < this.rowHeaders.length ; x++){     
                    listOfVendors.forEach(vendor => {
                        if(vendor.vcn === this.columnHeaders[y]){
                            if(this.rowHeaders[x] === 'Total Invoice Amount'){
                                this.pivotTableData[y][x] = '$ ' + this.formatPrice(vendor['Total Invoice Amount']);
                            }
                            if(this.rowHeaders[x] === 'Paid Invoice Amount'){
                                this.pivotTableData[y][x] = '$ ' + this.formatPrice(vendor['Paid Invoice Amount']);
                            }
                            if(this.rowHeaders[x] === 'Unpaid Invoice Amount'){
                                this.pivotTableData[y][x] = '$ ' + this.formatPrice(vendor['Unpaid Invoice Amount']);
                            }
                            if(this.rowHeaders[x] === 'Total Estimate Amount'){
                                if(vendor.append_missing_est_text){
                                    if(this.formatPrice(vendor['Total Estimate Amount']) != 0 ){
                                        this.pivotTableData[y][x] = '$ ' + this.formatPrice(vendor['Total Estimate Amount']) + " (Some lines do not have service estimates)";
                                    } else {
                                        this.pivotTableData[y][x] = '$ ' + this.formatPrice(vendor['Total Estimate Amount']);
                                    }                         
                                } else {
                                    this.pivotTableData[y][x] = '$ ' + this.formatPrice(vendor['Total Estimate Amount']);
                                }                     
                            }                            
                        }
                    })
                }
            } 
        },
        //
        //  Determines the x-axis headers based on what is selected in the dropdown box
        //  Returns the filtering condition
        //  For example 'well' returns 'uwi' as the filtering condition
        //
        determineXAxisHeader(resetFilters){
            let xFilterBy;
            if(this.xAxisSelection === "Well"){
                this.lodash.uniqBy(this.listOfAfes,'uwi').forEach(afe => {
                    this.rowHeaders.push(afe.uwi);
                });   
                xFilterBy = 'uwi';               
            }
            else if(this.xAxisSelection === 'AFE / PO'){
                this.lodash.uniq(this.listOfAfes).forEach(afe => {
                    this.rowHeaders.push(afe.afenum);
                });
                xFilterBy = 'afenum';                           
            }
            else if(this.xAxisSelection === "Category"){
                this.lodash.uniq(this.listOfAfes).forEach(afe => {
                    this.rowHeaders.push(afe.category);
                }); 
                this.rowHeaders = this.lodash.uniq(this.rowHeaders);
                    xFilterBy = 'category';                     
            }
            else if(this.xAxisSelection === "Project"){
                this.lodash.uniq(this.listOfProjects).forEach(project => {
                    this.rowHeaders.push(project.projectname);
                });    
                    xFilterBy = 'projectname';                       
            }
            else if(this.xAxisSelection === 'Cost Code #1 / Major'){
                this.listOfServicesLines.forEach(cc1 => {
                    this.rowHeaders.push(cc1.ccone_code);
                });                 
                this.rowHeaders = this.lodash.uniq(this.rowHeaders);
                xFilterBy = 'ccone_code';    
            }
            else if(this.xAxisSelection === 'Cost Code #2 / Minor'){
                this.listOfServicesLines.forEach(cc2 => {
                    this.rowHeaders.push(cc2.cctwo_code);
                });                 
                this.rowHeaders = this.lodash.uniq(this.rowHeaders);
                xFilterBy = 'cctwo_code';                      
            }
            else if(this.xAxisSelection === 'Cost Code #3 / Description'){
                this.listOfServicesLines.forEach(cc3 => {
                    this.rowHeaders.push(cc3.ccthree_code);
                });                 
                this.rowHeaders = this.lodash.uniq(this.rowHeaders);
                xFilterBy = 'ccthree_code';                              
            }
            else if(this.xAxisSelection === 'Line Item'){
                this.listOfServicesLines.forEach(line => {
                    this.rowHeaders.push(line.name + ' ' + '(' + line.uom + ')');
                });
                this.rowHeaders = this.lodash.uniq(this.rowHeaders);
                xFilterBy = 'name';  
            }
            else if (this.xAxisSelection === 'Invoice Payment'){
                this.rowHeaders.push("Total Invoice Amount");                    
                this.rowHeaders.push("Paid Invoice Amount"); 
                this.rowHeaders.push("Unpaid Invoice Amount"); 
                this.rowHeaders.push("Total Estimate Amount"); 
                xFilterBy = 'invoice_payment';
            }  
            else { 
                this.$toasted.show('xAxis Headers not set', {type: 'error', duration: '1000'});
                return;
            }  
     

            //  Populate the row options/selection for first time
            if(this.columnFilterOptions.length < 1 || resetFilters){
                this.columnFilterOptions = this.rowHeaders;
                this.columnFilterSelection = this.rowHeaders;
                this.columnRemovedItems = [];
            }


            this.rowHeaders = this.lodash.difference(this.rowHeaders,this.columnRemovedItems);

            return xFilterBy;
        },
        //
        //  Called when the toggle GST button slider is changed
        //
        toggleGST(event){
            this.buildTable();
        },
        //
        //  Called when the dropdown selection changes, used to filter out certian options 
        //  That dont make sense, such as "vendor => quantity"
        //
        selectionChanged(axis){
            let newXOptions = [];
            let newYOptions = [];
            let newDataOptions = [];

            //  Only display valid options for other dropdown
            if(axis === "xAxis"){
                this.pivotCompletion.filter(validRoute => {
                    return validRoute[1] === this.xAxisSelection;
                }).forEach(route => {
                    newYOptions.push(route[2]);
                })
                this.yAxisOptions = this.lodash.uniq(newYOptions);
                this.dataSelection = null;

                
                this.xAxisDim2Selection = [];

                if(this.xAxisSelection === 'Cost Code #3 / Description'){
                    this.xAxisDim2Options = ['Cost Code #2 / Minor','Cost Code #1 / Major','AFE / PO','Category','Well','Project'];
                } 

                if(this.xAxisSelection === 'Cost Code #2 / Minor'){
                    this.xAxisDim2Options = ['Cost Code #1 / Major','AFE / PO','Category','Well','Project'];
                } 

                if(this.xAxisSelection === 'Cost Code #1 / Major'){
                    this.xAxisDim2Options = ['AFE / PO','Category','Well','Project'];
                } 

                if(this.xAxisSelection === 'AFE / PO' || this.xAxisSelection === 'Category' || this.xAxisSelection === 'Well'){
                    this.xAxisDim2Options = ['Project'];
                } 

                if(this.xAxisSelection === 'Project'){
                    this.xAxisDim2Options = [];
                }

            }


            //  Only display valid options for other dropdown
            if(axis === "yAxis"){
                this.pivotCompletion.filter(validRoute => {
                    return validRoute[2] === this.yAxisSelection;
                }).forEach(route => {
                    newXOptions.push(route[1]);
                })
                this.xAxisOptions = this.lodash.uniq(newXOptions);
                this.dataSelection = null;
            }

            if(this.xAxisSelection && this.yAxisSelection){
                this.pivotCompletion.filter(validRoute => {
                    return validRoute[1] === this.xAxisSelection;
                }).filter(validRoute => {
                    return validRoute[2] === this.yAxisSelection;
                }).forEach(route => {
                    newDataOptions.push(route[0]);
                })
                this.dataOptions = this.lodash.uniq(newDataOptions);
                this.disableDataDropdown = false;
            }

            //  Special case for invoice payment
            if(this.yAxisSelection === 'Vendor' && this.xAxisSelection === 'Invoice Payment' && this.dataSelection === 'Total With Discount'){
                this.isInvoicePayment = true;
                this.buildTable(true);
            } else {
                this.isInvoicePayment = false;
            }

        },
        //
        //  Just gets all the projects from an API call puts into this.listOfProjects
        //
        async getAllProjects(){ 
            //console.log('#1 Getting all projects');
            return this.$axios.get('wts/get/projects').then(response => {
                this.listOfProjects = response.data.result;
                //console.log("Got projects:");
                //console.dir(this.listOfProjects);
            }).catch(error => {});
        },
        //
        //  Gets the processed invoices for each projectid and put into this.listOfInvoices
        //  
        async getAllProcessedInvoices(projectid){
            //  Processed invoices
            //console.log("#2 Getting all processed invoices for project " + projectid);
            return this.$axios.get('/wts/get/processed_invoices/' + projectid)
            .then(response => {
                let invoices = response.data.result;
                invoices.forEach(invoice => {
                    if(!this.listOfInvoices.includes(invoice)){
                        this.listOfInvoices = this.listOfInvoices.concat(invoice);
                    }
                })
            }).catch(error => {})       
        },
        //
        //  Gets the unprocessed invoices for each projectid and put into this.listOfInvoices
        //          
        async getAllUnprocessedInvoices(projectid){
            //  Unprocessed invoices
            //console.log("#3 Getting all unprocessed invoices for project " + projectid);
            return this.$axios.get('/wts/get/unprocessed_invoices/' + projectid)
            .then(response => {
                let invoices = response.data.result;
                invoices.forEach(invoice => {
                    if(!this.listOfInvoices.includes(invoice)){
                        this.listOfInvoices = this.listOfInvoices.concat(invoice);
                    }
                })
            }).catch(error => {})     
        },
        //
        //  Gets all the line items for the give invoice (puts its this.listOfServiceLines)
        //  
        async getAllServiceLinesFromInvoice(invoice){
            //console.log('getting invoice services for invoice ' +invoice.invoiceid);
            return this.$axios.get('/wts/get/invoice_services/'+invoice.projectid+'/'+invoice.invoiceid)
            .then(response => {
                let lines = response.data.result;
                lines.forEach(line => {
                    if(!this.listOfInvoices.includes(line)){
                        this.listOfServicesLines = this.listOfServicesLines.concat(line);
                    }

                });
            }).catch(error => {})
        },
        //
        //  Called when a selection is removed from dropdown
        //
        onRemoveRow(removed){
            console.log(removed);
            this.rowRemovedItems.push(removed);
            this.buildTable(false);
        }, 
        onRemoveColumn(removed){
            console.log(removed);
            this.columnRemovedItems.push(removed);
            this.buildTable(false);
        },   
        onRemoveProjectSelection(removed){
            console.log(removed);
            this.projectRemovedItems.push(removed);
            this.buildTable(false);
        },    
        onRemoveAfeSelection(removed){
            console.log(removed);
            this.afeRemovedItems.push(removed);
            this.removeAfe = true;
            this.buildTable(false);
        },     
        //
        //  Called when a selection is added from dropdown
        //        
        onSelectRow(selected,id){
            console.log(selected);
            this.rowRemovedItems.splice(this.rowRemovedItems.indexOf(selected),1);
            this.buildTable();
        },
        onSelectColumn(selected,id){
            console.log(selected);
            this.columnRemovedItems.splice(this.columnRemovedItems.indexOf(selected),1);
            this.buildTable();
        },
        onSelectProjectSelection(selected,id){
            console.log(selected);
            this.projectRemovedItems.splice(this.projectRemovedItems.indexOf(selected),1);
            this.buildTable();
        },
        onSelectAfeSelection(selected,id){
            console.log(selected);
            this.afeRemovedItems.splice(this.afeRemovedItems.indexOf(selected),1);
            this.buildTable();
        },

        clearSelection(){

            this.xAxisOptions = ['Well','AFE / PO', 'Category','Project','Cost Code #1 / Major','Cost Code #2 / Minor','Cost Code #3 / Description','Line Item'],        
            this.xAxisDim2Options = ['Well','AFE / PO', 'Category','Project','Cost Code #1 / Major','Cost Code #2 / Minor','Cost Code #3 / Description','Line Item'],        
            this.yAxisOptions = ['Vendor','Line Item','Project','Days'],
            this.dataOptions = ['Total With Discount','Quantity'],
            this.xAxisSelection = [];
            this.yAxisSelection = [];
            this.dataSelection = [];
        },
        
        createTestData(){
            //
            //  POPULATE TEST DATA
            //
            this.rowHeaders = ['one','two','three','four','five','six','seven','eight'];
            this.columnHeaders = ['datum1','datum2','datum3','datum4','datum5','datum6','datum7'];

            //
            //  INIT 2D ARRAY
            //
            this.pivotTableData = [];
            for(let y = 0 ; y < this.columnHeaders.length ; y++){
                this.pivotTableData.push([]);
                this.pivotTableData[y].push(new Array(this.rowHeaders.length));
                for(let x = 0 ; x < this.rowHeaders.length ; x++){
                    this.pivotTableData[y][x] = Math.floor(Math.random() * (9999 - 0)) + 0;
                }
            }
            this.$toasted.show('Test Data Created', {type: 'success', duration: '1000'});
        },

    }, 
    mounted: function () {
        if(typeof this.filterSelection === 'undefined'){
            return;
        }
        this.xAxisSelection = this.filterSelection.xAxisSelection;
        this.yAxisSelection = this.filterSelection.yAxisSelection;
        this.dataSelection = this.filterSelection.dataSelection;
        this.buildTable();
        this.disableDataDropdown=false;
    }
}
</script>


<style>

</style>